export enum Step {
  Select = 0,
  Emergency = 1,
  RegType = 2,
  Patient = 3,
  SendCode = 4,
  VerifyCode = 5,
  Medicare = 6,
  NextOfKin = 7,
  Prescription = 8,
  Confirm = 9,
  Payment = 10,
  ThankYou = 11,
}
