import React  from 'react';
import './Footer.scss';

export const Footer = React.memo(() => {
    return (
        <div className="d-flex justify-content-center body-text footer">
            Our Sage is part of
            <a href="https://www.cornerstonehealth.com.au/" target="_blank" rel="noreferrer">
                <img src="images/svg/CornerstoneHealth.svg" alt="CornerstoneHealth" height="14px"/>
            </a>
        </div>
    )
});
