import React from "react";
import "./ProgressBar.scss";

type Props = {
  waitText: string;
  errorText: string;
};
export const ProgressBar = React.memo(({ waitText, errorText }: Props) => {
  return (
    <>
      <div
        id="waiting-bar-mobile"
        className={`waiting-time-mobile waiting-time ${
          errorText ? "unavailable" : ""
        }`}
      >
        <div className="inner-wait-mobile columnGap-2">
          {!errorText && (
            <img
              src="images/svg/Icon_Waiting.svg"
              width="32"
              height="32"
              className="wait_icon"
              alt="waiting icon"
            />
          )}
          {errorText && (
            <img
              src="images/svg/Icon_Error_Red.svg"
              width="32"
              height="32"
              className="warning_icon align-self-start"
              alt="warning icon"
            />
          )}
          <div className="wait_text">{errorText || waitText}</div>
        </div>
      </div>

      <div
        id="waiting-bar-desktop"
        className={`waiting-time-desktop waiting-time ${
          errorText ? "unavailable" : ""
        }`}
      >
        <div className="align-self-center inner-waiting-time columnGap-2">
          {!errorText && (
            <img
              src="images/svg/Icon_Waiting.svg"
              width="32"
              height="32"
              className="wait_icon"
              alt="waiting icon"
            />
          )}
          {errorText && (
            <img
              src="images/svg/Icon_Error_Red.svg"
              width="32"
              height="32"
              className="warning_icon align-self-start"
              alt="warning icon"
            />
          )}
          <div className="wait_text">{errorText || waitText}</div>
        </div>
      </div>
    </>
  );
});
