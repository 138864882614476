import React, { MouseEventHandler, useEffect, useState } from "react";

interface Props {
  id?: string;
  name: string;
  label: string;
  checked: boolean;
  error?: boolean;
  onClick: MouseEventHandler<HTMLInputElement>;
}
export const CheckboxControl = React.memo(
  ({ id, name, label, checked, error, onClick }: Props) => {
    const [errorClass, setErrorClass] = useState(error ? "error" : "");

    useEffect(() => {
      setErrorClass(error ? "error" : "");
    }, [error]);

    return (
      <>
        <div className="col-sm-12 form-group mb-5 p-0">
          <label
            htmlFor={id || name}
            className={`checkbox-container ${errorClass}`}
          >
            <small>{label}</small>
            <input
              type="checkbox"
              id={id || name}
              name={name}
              checked={checked}
              onClick={onClick}
              readOnly
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </>
    );
  }
);
