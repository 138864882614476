import { Box, Modal } from "@mui/material";
import React from "react";
import { ModalStyle } from "../constants";

interface Props {
  open: boolean;
  handleClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const NoMatchingDetailsModal = React.memo(({ open, handleClose }: Props) => {
  const buttonClick = (value: string) => {
    handleClose({
      target: {
        name: "noMatchingDetails",
        value: value,
        checked: value,
      },
    } as any);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="text-center mb-4">
          <img src="images/svg/Icon_Blue_Alert.svg" alt="alert" />
        </div>
        <h3 id="singleNameModalLabel" className="modal-heading mb-3">
          No matching details found
        </h3>
        <p className="modal-text">
          Are your details correct? Please check your details or proceed as a
          new patient
        </p>
        <div className="d-flex flex-column justify-content-start columnGap-1 mt-4">
          <button
            className="btn btn-block modal-btn-outline btn-singleName-yes"
            style={{ padding: "12px 24px" }}
            type="button"
            onClick={() => buttonClick("")}
          >
            Back to edit
          </button>
          <button
            className="btn btn-block btn-solid active btn-singleName-no"
            type="button"
            onClick={() => buttonClick("newpatient")}
          >
            Restart as new patient
          </button>
        </div>
      </Box>
    </Modal>
  );
});

export default NoMatchingDetailsModal;
