import React from "react";
import { Box, Modal } from "@mui/material";
import { ModalStyle } from "../constants";

interface Props {
  open: boolean;
  handleClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const SingleNameModal = React.memo(({ open, handleClose }: Props) => {
  const buttonClick = (value: boolean) => {
    handleClose({
      target: {
        name: "singleName",
        value: value,
        checked: value,
      },
    } as any);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="text-center mb-4">
          <img
            src="images/svg/Icon_blue_question_mark.svg"
            alt="question mark icon"
          />
        </div>
        <h3 id="singleNameModalLabel" className="modal-heading pb-3">
          Only have a single name?
        </h3>
        <div className="modal-text">(Only have first name or last name)</div>
        <div className="justify-content-center" style={{ display: "grid" }}>
          <div className="text-center pb-3">
            <button
              className="btn btn-block btn-solid active btn-singleName-yes"
              style={{ padding: "12px 24px" }}
              type="button"
              onClick={() => buttonClick(true)}
            >
              Yes, a single name only
            </button>
          </div>
          <div className="text-center">
            <button
              className="btn btn-block modal-btn-outline btn-singleName-no"
              type="button"
              onClick={() => buttonClick(false)}
            >
              No, I have both first and last name
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
});

export default SingleNameModal;
