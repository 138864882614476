import React from "react";

interface Props {
  closeClick: Function;
}
const ThankYou = React.memo(({ closeClick }: Props) => {
  return (
    <div
      className="cs-chkin-form-panel js-active"
      data-animation="fadeIn"
      id="finalPanel"
    >
      <div className="col-11 p-0 d-flex flex-column align-items-center rowGap-2">
        <img src="images/Image_consultComplete.png" width="512" alt="thanks" />

        <p className="wt-room-heading">Thank You!</p>

        <p className="center-text">
          You will be contacted for a brief phone consultation before your
          prescription is issued.
        </p>

        <p className="center-text">
          In the mean time, download the free Our Sage App to easily access your
          eScripts & documents.
        </p>

        <div className="d-sm-flex justify-content-between columnGap-4 top">
          <img
            src="images/svg/apple_store.svg"
            width="168px"
            className="col-8 col-sm-6 offset-2 offset-sm-0 mb-3 cursor-pointer"
            alt="app store"
            id="app_store"
          />
          <img
            src="images/svg/google_play.svg"
            width="181px"
            className="col-8 col-sm-6 offset-2 offset-sm-0 mb-3 cursor-pointer"
            alt="play store"
            id="play_store"
          />
        </div>

        <div className="button-column col-12 top">
          <button
            className="btn btn-lg btn-block btn-outline"
            id="finish"
            type="button"
            onClick={() => closeClick()}
          >
            Close
          </button>
        </div>

        <img
          src="images/svg/Image_OurSage_Logo.svg"
          className="watermark"
          alt="watermark"
        />
      </div>
    </div>
  );
});

export default ThankYou;
