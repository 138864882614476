import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import "./InputControl.scss";
import { getValidDatePart } from "../../../helpers";

interface Props {
  type: string;
  label: string;
  value?: string;
  name: string;
  id?: string;
  maxlength?: number;
  subtext?: string;
  focus?: boolean;
  error?: boolean;
  disabled?: boolean;
  triggerChangeOnKeyup?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const InputControl = React.memo(
  ({
    type,
    label,
    value,
    name,
    id,
    maxlength,
    subtext,
    focus,
    error,
    disabled,
    triggerChangeOnKeyup,
    onChange = () => {},
  }: Props) => {
    const [cssClass, setCssClass] = useState(value ? "" : "empty");
    const [errorClass, setErrorClass] = useState(error ? "error" : "");
    const inputRef = useRef(null);

    useEffect(() => {
      focus && inputRef.current && (inputRef.current as any).focus();
    }, [focus]);

    useEffect(() => {
      setErrorClass(error ? "error" : "");
    }, [error]);

    const handleDate = (e: any): string => {
      let newValue = "";
      let input = e.target.value;
      input = input.replace(/\D/g, "");

      const day = input.substring(0, 2);
      const month = input.substring(2, 4);
      const year = input.substring(4, 8);

      if (input.length <= 2) {
        newValue = getValidDatePart(day, 31);
      } else if (input.length <= 4) {
        newValue = `${day}/${getValidDatePart(month, 12)}`;
      } else {
        newValue = `${day}/${month}/${year}`;
      }
      return newValue;
    };

    const handleExpiry = (e: any): string => {
      let newValue = "";
      let input = e.target.value;
      input = input.replace(/\D/g, "");

      const month = input.substring(0, 2);
      const year = input.substring(2, 6);

      if (input.length <= 2) {
        newValue = getValidDatePart(month, 12);
      } else if (input.length > 2) {
        newValue = `${month} / ${year}`;
      }
      return newValue;
    };

    const handleMobile = (e: any): string => {
      let input = e.target.value;
      return input.replace(/\D/g, "");
    };

    const handleChange = (e: any) => {
      setErrorClass("");
      if (!["date", "expiry", "mobile"].includes(type)) {
        error = false;
        onChange(e);
        return;
      }

      let newValue = "";
      if (type === "date") {
        newValue = handleDate(e);
      } else if (type === "expiry") {
        newValue = handleExpiry(e);
      } else if (type === "mobile") {
        newValue = handleMobile(e);
      }

      onChange({
        target: {
          name: e.target.name,
          value: newValue,
        },
      } as any);
    };

    useEffect(() => {
      setCssClass(value ? "" : "empty");
    }, [value]);

    return (
      <>
        <div
          className={`col-sm-12 form-group float-label-control 
          ${subtext ? "mb-1" : ""}
          ${errorClass}`}
        >
          <label htmlFor={id || name} className={cssClass}>
            {label}
          </label>
          <input
            type={type === "date" ? "text" : type}
            className="form-control"
            id={id || name}
            name={name}
            data-req="true"
            value={value}
            disabled={disabled}
            maxLength={maxlength}
            onChange={handleChange}
            onKeyUp={(e) => {
              if (triggerChangeOnKeyup) {
                handleChange(e);
              }
            }}
            onFocus={() => setCssClass("")}
            onBlur={(e) => setCssClass(e.target.value ? "" : "empty")}
            ref={inputRef}
          />
        </div>
        {subtext && <div className="subtext">{subtext}</div>}
      </>
    );
  }
);
