import { parse, format } from "date-fns";
import { GAEvent, PatientDetails } from "../models";

export const FormatName = (patientDetails: PatientDetails): string => {
  return patientDetails.singleName
    ? patientDetails.lName
    : `${patientDetails.fName} ${patientDetails.lName}`;
};

export const FormatDOB = (dateString: string): string => {
  const parsedDate = parse(dateString, "dd-MM-yyyy", new Date());

  return format(parsedDate, "dd/MM/yyyy");
};

export const maskEmail = (email?: string): string => {
  if (!email || email.indexOf("@") === -1) {
    return "";
  }
  return `${email.substring(0, email.indexOf("@") + 1)}****`;
};

export const maskMobile = (mobile?: string): string => {
  if (!mobile || mobile.length < 4) {
    return "";
  }
  return `****${mobile.substring(mobile.length - 4)}`;
};

export function sendGAEvent(gaevent: GAEvent) 
{
  const payload = Object.assign(
    {
      event: gaevent.event,
      event_id: crypto.randomUUID(),
      user_id: gaevent.user_id,
      step: gaevent.step,
      step_name: gaevent.step_name,
    },
    gaevent.extra || {}
  );
  window.dataLayer.push(payload);
}

export function toggleChatbot(display: boolean) {
  try {
    window.zE('messenger', display ? 'show' :'hide');
  } catch(e) {
    console.log('toggleChatbot', e)
  }
}
