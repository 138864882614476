import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";

export interface SelectOption {
  value: string;
  label: string;
}
interface Props {
  id?: string;
  name: string;
  label: string;
  focus?: boolean;
  value?: string;
  error?: boolean;
  options: SelectOption[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
}
export const SelectControl = React.memo(
  ({ id, name, label, value, focus, error, options, onChange }: Props) => {
    const [cssClass, setCssClass] = useState(value ? "" : "empty");
    const [errorClass, setErrorClass] = useState(error ? "error" : "");
    const inputRef = useRef(null);

    useEffect(() => {
      focus && inputRef.current && (inputRef.current as any).focus();
    }, [focus]);

    useEffect(() => {
      setErrorClass(error ? "error" : "");
    }, [error]);

    useEffect(() => {
      setCssClass(value ? "" : "empty");
    }, [value]);

    return (
      <>
        <div
          className={`col-sm-12 form-group float-label-control ${errorClass}`}
        >
          <label htmlFor={id || name} className={cssClass}>
            {label}
          </label>
          <select
            className="form-control"
            id={id || name}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={() => setCssClass("")}
            onBlur={(e) => {
              setCssClass(e.target.value ? "" : "empty");
            }}
            ref={inputRef}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
);
