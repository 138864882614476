import { useEffect, useRef, useState } from "react";

interface Props {
  label?: string;
  text: string;
  id?: string;
  name: string;
  rows?: number;
  maxLength?: number;
  subtext?: string;
  error?: boolean;
  focus?: boolean;
  onChange: any;
}
const TextareaControl = ({
  label,
  text,
  id,
  name,
  rows,
  maxLength,
  subtext,
  error,
  focus,
  onChange,
}: Props) => {
  const [errorClass, setErrorClass] = useState(error ? "error" : "");
  const textareaRef = useRef(null);

  useEffect(() => {
    focus && textareaRef.current && (textareaRef.current as any).focus();
  }, [id, focus]);

  useEffect(() => {
    setErrorClass(error ? "error" : "");
  }, [id, error]);

  return (
    <div className={`form-group float-label-control pb-5" ${errorClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        rows={rows ? rows : 4}
        id={id}
        style={{
          resize: "vertical",
          width: "100%",
          paddingTop: `${label == null ? "7px" : undefined}`,
        }}
        data-req="true"
        className="form-control"
        name={name}
        maxLength={maxLength}
        onChange={onChange}
        value={text}
        ref={textareaRef}
      ></textarea>
      {subtext && <div>{subtext}</div>}
    </div>
  );
};

export default TextareaControl;
