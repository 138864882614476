import { Prescription, Session, ValidatePatientForm } from "../models";

export const InitialSession: Session = {
  cid: -1,
  patientType: 0,
  newPrice: 45,
  repeatPrice: 15,
  waitText: "",
  checkinMessage: "",
  feeText:
    "The consult fee will be held as a pre-authorised transaction. Final payment is processed after the consult.",
  verificationCode: "",
  vopt: "M",
  mob: "",
  codeCount: 0,
  authorised: false,
  sessionId: crypto.randomUUID()
};

export const InitialValidatePatientForm: ValidatePatientForm = {
  fName: "",
  lName: "",
  singleName: false,
  dob: "",
  mobileNo: "",
  email: "",
  maskedEmail: "",
  maskedMobile: "",
  gender: "",
  gender_identity: "",
  address: "",
  suburb: "",
  pcode: "",
  isPostcodeAuthenticated: undefined,
};

export const InitialSendVerificationCodeForm = {
  mobileNo: "",
  email: "",
  maskedMobile: "",
  maskedEmail: "",
  vopt: "M",
  patientExists: false,
  verificationCode: "",
};

export const InitialContactDetailsForm = {
  address: "",
  suburb: "",
  pcode: "",
  isPostcodeAuthenticated: undefined,
};

export const InitialEmergencyNextOfKinForm = {
  emer_fname: "",
  emer_lname: "",
  emer_contact_no: "",
  emer_rel: "",
  emer_tick: false,
  kin_fname: "",
  kin_lname: "",
  kin_contact_no: "",
  kin_rel: "",
};

export const InitialMedicareDetailsForm = {
  medicareNo: "",
  medicarelineno: "",
  exp_medi: "",
  noMedicare: false,
  IHI: "",
};

export const InitialVoucherState = {
  sessionId: "",
  correlationId: crypto.randomUUID(),
  voucherStatus: undefined,
  consumerId: "",
  voucherRefGuid: "",
  code: "",
  isPromo: false,
};

export const InitialPrescription: Prescription = {
  version: "",
  price: 0,
  emergency: "No",
  repeat: false,
  appointmentType: {},
  phone: "",
  antibiotics: "",
  weightloss: "",
  medication: "",
  condition: "",
  takingOtherMedications: "",
  otherMedications: "",
  allergic: "",
  allergicMedications: "",
  agree: false,
  visitNotes: "",
};

export const InitialConfirmForm = {
  name: "",
  mobileNo: "",
  email: "",
  prescriptionType: "",
  price: 0,
  agreeTCs: false,
  acknowledge: false,
};
