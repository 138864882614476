import React, { useEffect, useRef, useState } from "react";

interface Props {
  label: string;
  handleClick: any;
  isLoading: boolean;
  isActive?: boolean;
  isError?: boolean;
  isFocus?: boolean;
}
export const Button = React.memo(
  ({ label, handleClick, isLoading, isActive, isError, isFocus }: Props) => {
    const [buttonCssClass, setButtonCssClass] = useState("");
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      setButtonCssClass(isActive ? "active" : "");
    }, [isActive]);

    useEffect(() => {
      if (isFocus && buttonRef.current) {
        buttonRef.current.focus();
      }
    }, [isFocus, buttonRef]);

    return (
      <button
        className={`btn btn-lg  btn-block btn-solid js-btn-next ${buttonCssClass}`}
        type="button"
        ref={buttonRef}
        onClick={handleClick}
      >
        {isLoading && <div className="loader"></div>}
        {!isLoading && label}
      </button>
    );
  }
);
