import { Step } from "../models";

export const Headings = {
  [Step.Select]: "Prescriptions",
  [Step.Emergency]: "Health details",
  [Step.RegType]: "Prescriptions",
  [Step.Patient]: "New patient",
  [Step.SendCode]: "Personal verification",
  [Step.VerifyCode]: "Personal verification",
  [Step.Medicare]: "Healthcare details",
  [Step.NextOfKin]: "Emergency contacts",
  [Step.Prescription]: "Repeat prescription",
  [Step.Confirm]: "Confirm Details",
  [Step.Payment]: "Payment details",
  [Step.ThankYou]: "Thank you",
};
