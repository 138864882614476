import React, { useEffect, useState } from "react";

export enum AlertBoxType {
  Info,
  Error,
}
interface Props {
  type: AlertBoxType;
  children: any;
}

export const AlertBox = React.memo(({ type, children }: Props) => {
  const [className, setClassName] = useState("blue-content-box");
  const [icon, setIcon] = useState("Icon_Information_blue.svg");

  useEffect(() => {
    if (type === AlertBoxType.Error) {
      setClassName("error-box d-flex");
      setIcon("Icon_Error_Red.svg");
    }
  }, [type]);

  return (
    <div className={`${className} mb-3 p-4`}>
      <img
        src={`images/svg/${icon}`}
        className="mr-3 align-self-start"
        alt="info icon"
      />
      <p className="body-text black-80 m-0">{children}</p>
    </div>
  );
});
