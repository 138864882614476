import { Box, Modal } from "@mui/material";
import React from "react";
import { ModalStyle } from "../constants";

interface Props {
  open: boolean;
  handleClose: (event: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
}

const PaymentFailedModal = React.memo(({ open, handleClose }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <div className="d-flex flex-column  align-items-center rowGap-2">
          <img src="images/svg/Icon_Blue_Alert.svg" alt="alert" />

          <div className="modal-heading mb-2" style={{ marginBottom: "40px" }}>
            Card declined
          </div>
          <div className="modal-text">
            Your card details are not valid. Please update your card details.
          </div>

          <button
            className="btn modal-btn-outline px-5"
            type="button"
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      </Box>
    </Modal>
  );
});

export default PaymentFailedModal;
